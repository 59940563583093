import ApiService, { RefundServie, PayAngelServie }  from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {
  COUNTRYLIST,
  ALLCOUNTRYLIST,
  SET_COUNTRY,
  SET_ALL_COUNTRY,
  CHECK_PHONE,
  CHECK_EMAIL,
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK_AUTH,
  UPDATE_USER,
  FORGOT_PASSWORD,
  VERIFY_EMAIL_INFO,
  VERIFY_EMAIL,
  VERIFY_EMAIL_RESEND,
  RESET_PASSWORD,
  HIDE_ERROR,
  COMBINELOADER,
  COUNTRYCODES,
  CONFIGURE_USER_SETTING,
  GET_CONFIGURE_USER_SETTING,
  PAYMENT_REFUND,
  PAYANGEL_LOGIN,
  TICKET_CURRENCY,
  GET_IP,
  CHECK_PHONEVALID
} from "./actions.type";
import { ISLOADING, ISBUTTONLOADING, SET_AUTH, UPDATE_AUTH_USER, PURGE_AUTH, SET_ERROR, ISLOADERCOMBINE, SET_EMAIL_VERIFY_TOKEN, CONFIGURTHEME, ISSIDEBARMINI, SET_PHONE_ERROR, SET_EMAIL_ERROR } from "./mutations.type";

  const state = {
    isLoading: false,
    isCombineLoader: false,
    isButtonLoading:false,
    errors: null,
    phoneError: null,
    emailError: null,
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    countryList: [],
    allCountryList: [],
    isVerifyEmail: false,
    userThemeConfiguration:{},
    isSidebarMini: false
  };

  const getters = {
    isLoading(state){
      return state.isLoading ;
    },
    isCombineLoader(state){
      return state.isCombineLoader;
    },
    isButtonLoading(state){
      return state.isButtonLoading ;
    },
    currentUser(state) {
      if(state.countryList && state.countryList.length != undefined){
        state.countryList.filter((elem) => {
          if(state.user && state.user.country_of_birth && elem.name == state.user.country_of_birth){
              state.user.country_of_birth = elem;
          }
          if(state.user && state.user.country_of_residence && elem.name == state.user.country_of_residence){
              state.user.country_of_residence = elem;
              if(state.user.country_of_residence.mobilecountrycode){
                state.user.phone = state.user.phone.replace(state.user.country_of_residence.mobilecountrycode,'');
              }
          }
        });
      }
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    countryList(state) {
      return state.countryList;
    },
    getAllcountryList(state) {
      return state.allCountryList;
    },
    isVerifyEmail(state){
      return state.isVerifyEmail;
    },
    userThemeConfiguration(state){
      return state.userThemeConfiguration;
    },
    isSidebarMini(state){
      return state.isSidebarMini;
    }
  };
  
  const actions = {
    [HIDE_ERROR](context) {
      context.commit(SET_ERROR, '');
    },

    async [CHECK_PHONEVALID](context, payload) {
      ApiService.init();
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.post(`check-phone-valid`, { phone: payload })
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          resolve(response);
        });
      });
    },

    async [TICKET_CURRENCY]() {
      const { data } = await ApiService.get(process.env.VUE_APP_STANDAPP_CURRENCY);
      let currency = []
      data.details.map((data)=>{
        data.currency = data.currencycode;
        data.isoCode = data.isocode = data.isocode.toLowerCase();
        data.name = data.currencycode;
        data.dialCode = data.mobilecountrycode;
        currency.push(data);
      });
      // data.details.map((data)=>{
      //   data.isoCode = data.isocode = data.isocode.toLowerCase();
      //   data.name = data.currencycode;
      //   data.dialCode = data.mobilecountrycode;
      //   currency.push(data);
      // });
      return currency;
    },
    async [COUNTRYLIST](context) {
      
      const { data } = await ApiService.get('https://standapp.net/api/v1/countries/source');
      let currency = []
      data.details.map((data)=>{
        // data.currency = data.currencycode;
        // data.isoCode = data.isocode = data.isocode.toLowerCase();
        // data.name = (data.name == 'United Kingdom' ? 'gb' : data.countryname );
        // data.dialCode = data.mobilecountrycode;
        data.currency = data.currencycode;
        data.isoCode = data.isocode = data.isocode.toLowerCase();
        data.name = (data.name == 'United Kingdom' ? 'gb' : data.countryname );
        data.dialCode = data.mobilecountrycode;
        currency.push(data);

      });
      context.commit(SET_COUNTRY, currency);
      return currency;
    },
    async [ALLCOUNTRYLIST](context) {
      
      const { data } = await ApiService.get(process.env.VUE_APP_STANDAPP_COUNTRY);
      let currency = []
      data.map((data)=>{
        data.currency = data.currencycode;
        data.isoCode = data.isocode = data.isocode.toLowerCase();
        data.name = (data.name == 'United Kingdom' ? 'gb' : data.countryname );
        data.dialCode = data.mobilecountrycode;
        currency.push(data);

      });
      context.commit(SET_ALL_COUNTRY, currency);
      return data;
    },
    async [COUNTRYCODES]() {
      const { data } = await ApiService.get(process.env.VUE_APP_MEMBERSHIP_DOMAIN+'country-codes');
      return data;
    },

//     curl --location 'https://stand-app.com/api/v2/checkout/refund-payment/pay_f3r3i2x5hlfkdkbszlhqjyr4q4/' \
// --header 'Content-Type: application/json' \
// --data '{
//     "amount":"1",
//     "reference":"Test",
//     "metadata":{"key":"test"}
// }'
    async [PAYMENT_REFUND](context, payload) {
      RefundServie.init();
      RefundServie.setHeader();
      context.commit(ISLOADING, true);
      return new Promise(resolve => {
        RefundServie.create(`${payload.paymentId}`,payload.body)
        .then((data) => {
          context.commit(SET_ERROR, '');
          resolve(data);
          context.commit(ISLOADING, false);
        })
        .catch(({ response }) => {
          context.commit(ISLOADING, false);
          context.commit(SET_ERROR, response.data.errors);
          resolve(response);
        });
      });
    },

    async [CONFIGURE_USER_SETTING](context, payload) {
      ApiService.init();
      context.commit(ISLOADING, true);
      
      var formDataUpdate = new FormData();
      formDataUpdate.append("_method", 'PATCH');
      formDataUpdate.append("mode", payload.mode);
      formDataUpdate.append("theme", payload.theme);
      return new Promise(resolve => {
        ApiService.post("users/settings", formDataUpdate)
          .then(({ data }) => {
            context.commit(CONFIGURTHEME, data.data);
            resolve(data);
            context.commit(ISLOADING, false);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            context.commit(ISLOADING, false);
          });
      });
    },
    [GET_CONFIGURE_USER_SETTING](context) {
      ApiService.init();
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.get("users/settings").then(({ data }) => {
          context.commit(CONFIGURTHEME, data.data);
          resolve(data)
        }).catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
      });
    },
    async [CHECK_EMAIL](context, payload) {
      ApiService.init();
      context.commit(ISBUTTONLOADING, true);
      return new Promise(resolve => {
        ApiService.post("check-email", payload)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            context.commit(SET_ERROR, '');
            context.commit(SET_EMAIL_ERROR, '');
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            console.log(state.errors);
            console.log("response >> >> ",response);
            context.commit(SET_EMAIL_ERROR, response.data.errors);
            //context.commit(SET_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
          });
      });
    },
    async [CHECK_PHONE](context, payload) {
      ApiService.init();
      context.commit(ISBUTTONLOADING, true);
      return new Promise(resolve => {
        ApiService.post("check-mobile", payload)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            context.commit(SET_PHONE_ERROR, '');
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            context.commit(SET_PHONE_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
            resolve(response);
          });
      });
    },
    async [LOGIN](context, credentials) {
      ApiService.init();
      ApiService.setHeader();
      context.commit(ISBUTTONLOADING, true);
      return new Promise(resolve => {
        ApiService.post("login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            context.commit(SET_ERROR, '');
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            if(response.status == 403){
              context.commit(SET_EMAIL_VERIFY_TOKEN, response.data.token);
            }
            context.commit(SET_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
            resolve(response);
          });
      });
    },
    async [PAYANGEL_LOGIN](context, reqpayload) {
      PayAngelServie.init();
      context.commit(ISBUTTONLOADING, true);
      let payload = {
        "UserLogin": {
          "RequestHeader": {
            "TransactionID": "ASDF-987-ADFKHJ-A987ASDF-98NKM",
            "ComChannel": "ANGELCOM",
            "SerialNo": "00000000001",
            "SessionIdentity": {
              "Username": "appuser",
              "password": "BLx7c$cGI*2q",
              "ApplicationCode": "APP005"
            }
          },
          "RequestBody": { 
            "Mobile": reqpayload.phone,
            "Password": reqpayload.password
          }
        }
      };
      
      return new Promise(resolve => {
        PayAngelServie.create("payangel_mobile_app_74/login", payload)
          .then(({ data }) => {
            context.commit(SET_ERROR, '');
            let user = {}
            user.data = data.UserLogin.ResponseBody.customer_data
            context.commit(UPDATE_AUTH_USER, user);
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            if(response.status == 403){
              context.commit(SET_EMAIL_VERIFY_TOKEN, response.data.token);
            }
            context.commit(SET_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
            resolve(response);
          });
      });
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
      context.commit(CONFIGURTHEME, {});
    },
    [REGISTER](context, data) {
      ApiService.init();
      context.commit(ISBUTTONLOADING, true);
      return new Promise(resolve => {
          ApiService.post("register", data)
          .then(({ data }) => {
            context.commit(SET_ERROR, '');
            context.commit(SET_AUTH, data);
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
              context.commit(SET_ERROR, response.data.errors);
              context.commit(ISBUTTONLOADING, false);
          });
      });
    },
    [CHECK_AUTH](context) {
      if (JwtService.getToken()) {
        ApiService.init();
        ApiService.setHeader();
        return new Promise(resolve => {
          ApiService.get("users/profile")
            .then(({ data }) => {
              context.commit(UPDATE_AUTH_USER, data);
              context.commit(SET_AUTH, data);
              resolve(data)
            })
            .catch(({ response }) => {
              context.commit(SET_ERROR, response.data.errors);
            });
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    },
    [UPDATE_USER](context, payload) {
      ApiService.init();
      ApiService.setHeader();
      return ApiService.post("users/profile", payload)
      .then(({ data }) => {
        context.commit(UPDATE_AUTH_USER, data);
        context.commit(SET_ERROR, '');
        return data;
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
        return response;
      });
    },
    [FORGOT_PASSWORD](context, payload) { 
      ApiService.init();
      if(payload.email){
        context.commit(ISBUTTONLOADING, true);
        return new Promise(resolve => {
          ApiService.post("forgot-password", payload)
            .then(({ data }) => {
              context.commit(SET_ERROR, '');
              resolve(data);
              context.commit(ISBUTTONLOADING, false);
            })
            .catch(({ response }) => {
              context.commit(SET_ERROR, response.data.errors);
              context.commit(ISBUTTONLOADING, false);
            });
        });
      }else{
        context.commit(SET_ERROR, '');
      }
    },
    [RESET_PASSWORD](context, payload) {
      ApiService.init();
      context.commit(ISBUTTONLOADING, true);
      payload.password_confirmation = payload.password;
      return new Promise(resolve => {
        ApiService.post("reset-password", payload)
          .then(({ data }) => {
            context.commit(SET_ERROR, '');
            resolve(data);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
          });
      });
    },
    async [VERIFY_EMAIL_INFO](context) {
      ApiService.init();
      ApiService.setHeaderVerifyEmail();
      context.commit(ISLOADING, true);
      const { data } = await ApiService.get(`users/verificationInfo`);
      context.commit(ISLOADING, false);
      return data;
    },
    [VERIFY_EMAIL](context, payload) {
      ApiService.init();
      ApiService.setHeader();
      context.commit(ISBUTTONLOADING, true);
      return new Promise(resolve => {
        ApiService.post("verify-email", payload)
          .then(({ response }) => {
            response = true;
            context.commit(SET_ERROR, '');
            resolve(response);
            context.commit(ISBUTTONLOADING, false);
          })
          .catch(({ response }) => {
            resolve(response);
            context.commit(SET_ERROR, response.data.errors);
            context.commit(ISBUTTONLOADING, false);
          });
      });
    },
    [VERIFY_EMAIL_RESEND](context) {
      ApiService.init();
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.post("email/verification-notification")
          .then((response) => {
            context.commit(SET_ERROR, '');
            resolve(response);
          })
          .catch(({ response }) => {
            resolve(response);
            context.commit(SET_ERROR, response.data.errors);
          });
      });
    },

    async [COMBINELOADER](context, payload) {
      context.commit(ISLOADERCOMBINE, payload);
    },

    async [GET_IP]() {
      const data = await ApiService.getIp(process.env.CUSTOMER_IP_BASE);
      console.log("data > > > ",data);
      return data;
    },

  };
  
  const mutations = {
    [ISSIDEBARMINI](state) {
      state.isSidebarMini = !state.isSidebarMini;
    },
    [CONFIGURTHEME](state, data) {
      state.userThemeConfiguration = data;
    },
    [ISLOADING](state, loading) {
      state.isLoading = loading;
    },
    [ISLOADERCOMBINE](state, loading) {
      state.isCombineLoader = loading;
    },
    [ISBUTTONLOADING](state, loading) {
      state.isButtonLoading = loading;
    },
    [SET_COUNTRY](state, data) {
      state.countryList = data;
    },
    [SET_ALL_COUNTRY](state, data) {
      state.allCountryList = data;
    },
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [SET_PHONE_ERROR](state, error) {
      state.phoneError = error;
    },
    [SET_EMAIL_ERROR](state, error) {
      state.emailError = error;
    },
    [SET_EMAIL_VERIFY_TOKEN](state, token) {
      state.isVerifyEmail = true;
      JwtService.saveToken(token);
    },
    [SET_AUTH](state, user) {
      state.isAuthenticated = true;
      state.errors = {};
      if(user.token){
        JwtService.saveToken(user.token);
      }
      state.user = user.data;
    },
    [UPDATE_AUTH_USER](state, user) {
      state.user = user.data;
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = {};
      state.errors = {};
      JwtService.destroyToken();
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  