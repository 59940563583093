<template>
  <div id="wrapper" v-bind:class="{
    'communities-landing': homeLandingPageClasses,
    'auth-wrapper landing-page auth standapp': authClassList
  }">
    <div class="page-loader-wrapper" v-if="isLoading">
      <div class="loader">
        <div>
          <img src="./assets/images/Angel_Communities_Logo.svg" width="200" height="45" alt="AngelCommunities" />
          <p>Please wait...</p>
        </div>
      </div>
    </div>
    <component :is="layout">
      <!-- <meta-info></meta-info> -->
      <router-view></router-view>
    </component>
  </div>
</template>
<script>

import {
  CHECK_AUTH,
  COUNTRYLIST,
  ALLCOUNTRYLIST,
  GET_ORGANISATIONS_TYPE,
  ORGANISATIONS,
  GET_TYPE_CATEGORY,
  HIDE_ERROR,
  VERIFY_EMAIL_INFO,
  GET_CONFIGURE_USER_SETTING
} from "./store/actions.type";
//const default_layout = "default";
//const default_layout = "frontend";
export default {
  name: "App",
  components: {},
  watch: {
    $route: {
      immediate: true, // also trigger handler on initial value
      handler() {
        this.$store.dispatch(HIDE_ERROR, "");
        if (this.$route.name === "maintanance") {
          document.body.className = this.data_theme != '' ? this.data_theme : '';
        } else {
          document.body.className = (this.activeColor == null) ? 'theme-sweet-orange' : "theme-" + this.activeColor;
        }
        if (this.$store.getters.isSidebarMini) {
          document.body.classList.add("layout-fullwidth");
        } else {
          document.body.classList.remove("layout-fullwidth");
        }
      },
    },
  },
  methods: {},
  data() {
    return {
      isLoading: true,
      activeColor: null,
      data_theme: null
    };
  },
  created() {
    this.interval = setInterval(() => {
      this.isLoading = false;
    }, 1000);
  },
  computed: {
    layout() {
      if (this.$route.meta.layout != undefined && this.$route.meta.layout != null) {
        return this.$route.meta.layout + "-layout";
      }
      return "default-layout";
    },
    homeLandingPageClasses() {
      let homeLandingPageClasses = [
        "home"
      ].includes(this.$route.name);
      return homeLandingPageClasses;
    },

    authClassList() {
      let authClass = ["front-login", "login", "register", "pdetail", "pwd", "resetpwd", "otp", "passcode", "fpwd"].includes(this.$route.name);
      return authClass;
    },
  },
  async mounted() {

    // if (location.protocol == 'https:') {
    //   this.$toast.open({
    //     message: 'Please try with "http" protocol.',
    //     type: 'error',
    //     duration: 10000,
    //     position: 'top-right',
    //     dismissible: true
    //   }) 
    // }

    this.$store.dispatch(GET_TYPE_CATEGORY);
    this.$store.dispatch(COUNTRYLIST);
    this.$store.dispatch(ALLCOUNTRYLIST);
    this.$store.dispatch(GET_ORGANISATIONS_TYPE);

    if (window.sessionStorage.getItem('id_token') != null) {
      if (this.$store.getters.isAuthenticated) {
        await this.$store.dispatch(VERIFY_EMAIL_INFO).then((response) => {
          this.isUserVerify = response.data;
          if (this.isUserVerify.is_email_verified) {
            this.$store.dispatch(GET_CONFIGURE_USER_SETTING).then((response) => {
              let configuredSetting = response.data;
              if (configuredSetting.mode && configuredSetting.theme) {
                this.data_theme = (configuredSetting.mode == "dark" ? "full-dark" : "");
                this.activeColor = configuredSetting.theme;
                if (this.$route.name != 'home') {
                  document.body.className = "theme-" + this.activeColor + " " + this.data_theme;
                  if (this.$store.getters.isSidebarMini) {
                    document.body.classList.add("layout-fullwidth");
                  } else {
                    document.body.classList.remove("layout-fullwidth");
                  }
                }
              }
            })
            this.$store.dispatch(CHECK_AUTH);
            this.$store.dispatch(ORGANISATIONS);
          }
        });
      }
    }
  },
  metaInfo() {
    return {
      title: "Angel communities",
      meta: [
        { name: 'description', content: 'Smart tool to manage your Members, Events, Payments and Collections' },
        { property: 'title', content: "Angel communities - Smart tool to manage your Members, Events, Payments and Collections" },
        { property: 'site_name', content: 'AngelCommunities' },
        { property: 'type', content: 'website' }
      ]
    }
  }
};
</script>
<style lang="scss">
@import "../src/assets/vendor/bootstrap/css/bootstrap.min.css";
@import "../src/assets/vendor/font-awesome/css/font-awesome.min.css";
@import "../src/assets/scss/main.scss";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
</style>
